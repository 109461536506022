import * as React from "react";
import { Grid, Row, Col} from "rsuite";
import LayoutMain from "components/layout/main";
import HeaderLarge from "components/section/headerLarge";
import SectionWrapper from "components/section/sectionWrapper";

const BronnenPage = () => {
  return (
    <LayoutMain>
      <HeaderLarge
        title="Disclaimer"
        description="Toegang tot en gebruik van de website van de Nederlandse Kennisplatform Antistolling (hierna Kennisplatform) is onderworpen aan onderstaande voorwaarden. Gebruik van het Kennisplatform betekent de volledige aanvaarding van en instemming met deze voorwaarden."
      />
      <SectionWrapper background>
        <Grid>
          <Row style={{ padding: "60px 0 80px 0" }}>
            <Col xs={18}>
              <h3 style={{ color: "#000", marginTop: "20px" }}>Doel</h3>
              <p style={{ lineHeight: "2", fontSize: "16px", width: "95%" }}>
                Alle gegevens en informatie op het Kennisplatform worden
                uitsluitend voor informatieve doeleinden ter beschikking
                gesteld. Hoewel aan de samenstelling en inhoud van het
                Kennisplatform de uiterste zorg wordt besteed, kan het
                Kennisplatform niet garanderen dat de aard en inhoud van de
                informatie juist zijn.
              </p>
            </Col>
            <Col xs={18}>
              <h3 style={{ color: "#000", marginTop: "20px" }}>Aansprakelijkheid</h3>
              <p style={{ lineHeight: "2", fontSize: "16px", width: "95%" }}>
                Het Kennisplatform is op geen enkele wijze aansprakelijk voor
                eventuele schade, direct dan wel indirect, die de bezoeker c.q.
                gebruiker van de Kennisplatform-site en/of derden lijden als
                gevolg van het gebruik van informatie, materiaal en/of diensten
                van de Kennisplatform en/of als gevolg van het gebruik van
                informatie, materiaal en/of diensten van de websites verbonden
                aan de Kennisplatform.
              </p>
            </Col>

            <Col xs={18}>
              <h3 style={{ color: "#000", marginTop: "20px" }}>Intellectuele eigendomsrechten</h3>
              <p style={{ lineHeight: "2", fontSize: "16px", width: "95%" }}>
                Alle auteursrechten en andere rechten van intellectueel eigendom
                op de inhoud van de Kennisplatform, waaronder mede begrepen
                tekst, vormgeving, beelden, logo’s en geluiden, behoren toe aan
                het Kennisplatform of haar licentiegevers. Zonder voorafgaande
                schriftelijke toestemming van het Kennisplatform mag de inhoud
                van de Kennisplatform niet worden verveelvoudigd, gewijzigd
                en/of openbaar gemaakt, in welke vorm of op welke wijze dan ook
                of worden opgeslagen in een geautoriseerd gegevens bestand.
              </p>
            </Col>

            <Col xs={18}>
              <h3 style={{ color: "#000", marginTop: "20px" }}>
                Koppeling naar websites van derden
              </h3>
              <p style={{ lineHeight: "2", fontSize: "16px", width: "95%" }}>
                Het Kennisplatform draagt geen verantwoordelijkheid voor de
                inhoud van de websites die gelinkt zijn aan het Kennisplatform,
                noch voor de informatie, software, producten en services die op
                of via deze sites te verkrijgen zijn. Een link naar een andere
                site is uitsluitend bedoeld voor het gemak van de gebruiker. Het
                Kennisplatform aanvaardt dienaangaande geen enkele verplichting
                of aansprakelijkheid. Zonder voorafgaande schriftelijke
                toestemming van het Kennisplatform is framing en het maken van
                een hyperlink naar de Kennisplatform niet toegestaan.
              </p>
            </Col>

            <Col xs={18}>
              <h3 style={{ color: "#000", marginTop: "20px" }}>Privacy</h3>
              <p style={{ lineHeight: "2", fontSize: "16px", width: "95%" }}>
                Alle door de gebruiker aan het Kennisplatform verstrekte
                gegevens zullen als vertrouwelijke informatie worden beschouwd
                en, tenzij expliciet anders kenbaar wordt gemaakt, niet door het
                Kennisplatform aan derden worden bekendgemaakt, behoudens indien
                dit bij wet is vereist of door de justitiële autoriteiten wordt
                verlangd.
              </p>
            </Col>
            <Col xs={18}>
              <h3 style={{ color: "#000", marginTop: "20px" }}>Toepasselijk recht</h3>
              <p style={{ lineHeight: "2", fontSize: "16px", width: "95%" }}>
                Deze voorwaarden, alsmede geschillen of vorderingen die
                voortvloeien uit of verband houden met de voorwaarden, worden
                beheerst door Nederlands recht. Dergelijke geschillen en
                vorderingen zullen bij uitsluiting worden voorgelegd aan een
                bevoegde rechter.
              </p>
            </Col>
            <Col xs={18}>
              <h3 style={{ color: "#000", marginTop: "20px" }}>
                Wijzigingen van deze voorwaarden
              </h3>
              <p style={{ lineHeight: "2", fontSize: "16px", width: "95%" }}>
                Het Kennisplatform behoudt zich het recht voor om de voorwaarden
                waaronder de Kennisplatform wordt aangeboden zonder nadere
                kennisgeving te wijzigen. Deze wijzigingen zullen direct na het
                plaatsen ervan op de Kennisplatform van kracht zijn.
              </p>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </LayoutMain>
  );
};

export default BronnenPage;
